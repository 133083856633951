// define 3 theme color
// mat-palette accepts $palette-name, main, lighter and darker variants
$md-siurgence: (
    50 : #ebf6f2,
    100 : #cde8df,
    200 : #acd8ca,
    300 : #8ac8b4,
    400 : #71bda4,
    500 : #273978,
    600 : #50aa8c,
    700 : #47a181,
    800 : #3d9877,
    900 : #2d8865,
    A100 : #ceffeb,
    A200 : #9bffd7,
    A400 : #68ffc2,
    A700 : #4effb8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-mcgpalette0: (
    50 : #ebf6f2,
    100 : #cde8df,
    200 : #acd8ca,
    300 : #8ac8b4,
    400 : #71bda4,
    500 : #273978,
    600 : #50aa8c,
    700 : #47a181,
    800 : #3d9877,
    900 : #2d8865,
    A100 : #ceffeb,
    A200 : #9bffd7,
    A400 : #68ffc2,
    A700 : #4effb8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-siurgence-accent: (
    50 : #f8f8f8,
    100 : #ededed,
    200 : #e2e2e2,
    300 : #d6d6d6,
    400 : #cdcdcd,
    500 : #c4c4c4,
    600 : #bebebe,
    700 : #b6b6b6,
    800 : #afafaf,
    900 : #a2a2a2,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffe5e5,
    A700 : #ffcccc,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* Custom typography */
$general-typography: mat.define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $headline-1: mat.define-typography-level(112px, 112px, 300),
  $headline-2: mat.define-typography-level(56px, 56px, 400),
  $headline-3: mat.define-typography-level(45px, 48px, 400),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 600),
  $headline-6: mat.define-typography-level(20px, 32px, 600),
  $subtitle-1: mat.define-typography-level(18px, 28px, 600),
  $subtitle-2: mat.define-typography-level(16px, 24px, 600),
  $body-2: mat.define-typography-level(14px, 24px, 500),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500)
);

$default-theme-primary: mat.define-palette($md-mcgpalette0, 500, 300, 900);
$default-theme-accent: mat.define-palette($md-siurgence-accent);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$default-theme: mat.define-light-theme((
  color: (
    primary: $default-theme-primary,
    accent: $default-theme-accent
  ),
  typography: $general-typography
));

// Update background color of theme
$custom-background-color: #ffffff;
$background: map-get($default-theme, background);
$background: map_merge($background, (background: $custom-background-color));
$default-theme: map_merge($default-theme, (background: $background));


/* Custom theme for generic details on the app */
@mixin generics($default-theme) {

  .mat-mdc-button-persistent-ripple { display: none;}


  .mat-expansion-panel-advanced-search-color {
    .mat-mdc-form-field,.mat-mdc-text-field-wrapper {
      background-color: transparent;
    }
    .mat-mdc-text-field-wrapper, .mdc-text-field__input, input::placeholder{
      color: white !important;
    }
    .separator,.dossier-count {
      margin-bottom: var(--mdc-typography-caption-line-height, 20px);
    }

    .mdc-line-ripple::before{
      color: white;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled){
      .mdc-line-ripple::before {
        border-bottom-color: white;
      }
      &:hover .mdc-line-ripple::before {
        border-bottom-color: white;
      }
    }

    .mat-mdc-select-arrow > svg {
      color: white;
    }
  }

  /* material icons default style*/
  .material-icons {
    font-family: 'Material Icons', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .material-icons-filled {
    font-family: 'Material Icons Filled', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .pointer {
    cursor: pointer;
  }

  .module-title {
    text-transform: uppercase;
    font-size: 35px;
    font-weight: bold;
  }

  .module-subtitle {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: bold;
  }

  .data-information {
    font-size: 18px;
    margin-left: 20px;
  }
}

@mixin customCdkOverlayStylings() {
  .mat-mdc-tooltip {
    background: black;
    padding: 10px;
    font-size: 12px;
  }

  .initialize-spinner.mat-mdc-progress-spinner circle {
    stroke: #009688;
  }

  .default-resume-snackbar .mat-mdc-simple-snackbar-action {
    color: #4db6ac;
  }

  .mat-mdc-expansion-panel-header[aria-disabled=true] .mat-mdc-expansion-panel-header-title, .app-theme .mat-mdc-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
    color: black
  }
}
