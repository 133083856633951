@use '@angular/material' as mat;

@include mat.core();

// import our custom theme
@import 'material-theme.scss';

// always include only once per project
@include mat.typography-hierarchy($default-theme);


html,
body {
  margin    : 0;
  height    : 100%;
  max-height: 100%;
  width     : 100%;
  max-width : 100%;
}

// specify theme class eg: <body class="app-theme"> ... </body>
.app-theme {

  // use our theme with angular-material-theme mixin
  @include mat.core-theme($default-theme);
  @include mat.all-component-themes($default-theme);
  @include generics($default-theme);
  @include customCdkOverlayStylings();
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style : normal;
  font-weight: 400;
  src: url('./assets/fonts/MaterialIconsOutlined-Regular.woff2') format('woff2');

}

/* fallback */
@font-face {
  font-family: 'Material Icons Filled';
  font-style : normal;
  font-weight: 400;
  src: url('./assets/fonts/Material-Icons-webfont.woff2') format('woff2');

}


.bold {
  font-weight: 900;
}

.male {
  color: #01579B;
}

.female {
  color: #C51162;
}

.snack-bar-success {
  background: #58b194 !important;
  color: black !important;
}
.mat-mdc-simple-snackbar-action{
  color: inherit !important;
}

@media print {

  .sidenav-content-container {
    margin-left: 0 !important;
  }

  .patient-detail {
    padding: 0 !important;
  }

  .tucs-listing {
    display: none;
  }

  .tucs-hide-on-print {
    display: none !important;
    width  : 0 !important;
  }

  .tucs-fiche {
    width    : 100% !important;
    font-size: 80% !important;
    padding: 0 !important;
  }

  .vehicule {
    display: none !important;
  }
}
